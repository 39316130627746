// consumer_sys_sms_vcodeSend 验证码发送
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/sms-controller/vcodeSendUsingPOST
const consumer_sys_sms_vcodeSend = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/sys/sms/vcodeSend',
        data: pParameter,
        check_token: pParameter.check_token !== undefined ? pParameter.check_token : false,
        enable_error_alert: pParameter.enable_error_alert !== undefined? pParameter.enable_error_alert:true,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_sys_sms_vcodeSend;