export default {
    data () {
        return {}
    },
    components: {},
    created () {
    },
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/