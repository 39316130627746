export default {
  data() {
    return {
      list: [
        {
          name: "下载APP",
          img:
            "https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2024/04/yingyongbao-qrcode-automatically-adapt-ua.png",
          text: '手机扫码快速下载',
        },
        {
          name: "微信公众号",
          img:
            "https://download.wholeok.com/static/image/quancheng/1-qrcode-gongzhonghao.jpg",
          text: '手机扫码关注',
        },
      ],
    };
  },
  methods: {

  },
};
