// consumer_web_company_companyRegiste 企业注册(注册步骤1)
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-controller/companyRegisteUsingPOST
const consumer_web_company_companyRegiste = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/companyRegiste',
        data: pParameter,
        check_token: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_company_companyRegiste;